<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">

            <!-- TODO: link to view account -->
            <!-- TODO: show featured products; allow organization to select which products are on front page, or set it up with special banners with a page builder, or something like that; and/or we can show links to view account, go to brand's homepage (link from their brandprofile), etc. we don't need to check if uesr is authenticated, because if they try to access a feature that is for authenticated users only they will get redirected to login page; -->
            <!-- TODO: the list of products needs to be styled better; if there's a product image then show the image and product name in a little card maybe -->
            <!-- TODO: show the cancel button differently, and make it clear that it empties the entire cart ... and not just goes back to last page... maybe there need to be separate buttons for these two things, properly labeled -->
            <!-- <p class="text-caption text-end">
            </p> -->
            <template v-if="isViewReady && cart">
            <!-- <v-row class="mt-5">
                <v-col style="text-align: end;">
                    <TextButton :color="primaryColor" underline @click="cancel">Clear</TextButton>
                </v-col>
            </v-row> -->
            <v-card>
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>Cart</v-app-bar-title>
                    <v-spacer/>
                    <!-- TODO: maybe -- make this a cart management menu with a dropdown icon to expand it, and put the 'cancel' option in the menu; future options will include saving some items for later, or stashing this cart and continue shopping -->
                    <TextButton color="white" underline @click="cancel">Clear</TextButton>
                </v-app-bar>
                <v-card-text v-if="cart.items.length === 0">
                    <p>There are no items in your cart.</p>
                </v-card-text>
                <v-simple-table  v-if="cart.items.length > 0">
                    <template #default>
                        <thead>
                            <tr>
                                <!-- <th style="text-align: start; width: 16px; vertical-align: middle;">
                                    <v-checkbox dense class="ma-0 checkout-item-selection"></v-checkbox>
                                </th> -->
                                <th style="text-align: start; width: 70%;">Item</th>
                                <th style="text-align: start; width: 150px;">Qty</th>
                                <th style="text-align: end;">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in cart.items" :key="'item__'+idx">
                                <!-- <td style="text-align: start; vertical-align: middle;">
                                    <v-checkbox dense class="ma-0 checkout-item-selection"></v-checkbox>
                                </td> -->
                                <td style="text-align: start;">
                                    <p class="mb-0">
                                    <!-- item.product_id -->
                                    {{ item.name }}
                                    <!-- {{ item.price_id }} -->
                                    </p>
                                    <p class="mb-0 text-caption" v-if="item.is_custom_price">
                                        <!-- <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/> -->
                                        You decide the amount
                                    </p>
                                    <p class="mb-0 text-caption" v-if="item.is_recurring">
                                        <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                                        Recurring charge every {{ item.recurring_interval.count }} {{ item.recurring_interval.unit }}
                                    </p>
                                    <p class="mb-0 text-caption">
                                        <a href="#" @click="removeFromCart(item.price_id)">Remove</a>
                                    </p>
                                </td>
                                <td style="text-align: start;">
                                    <template v-if="item.is_free">1</template>
                                    <template v-if="item.is_custom_price">1</template>
                                    <template v-if="item.is_flat_price && !item.is_metered">1</template>
                                    <template v-if="item.is_metered">Metered</template> <!-- TODO: add a question mark icon to bring up a little helpful text in a dialog to explain that charge will vary every month with usage, and if there's a minimum flat fee it will show up in the total column -->
                                    <!-- TODO: :price="item" below isn't really correct, this is a cart item, not an actual price, so it will only work if it defines 'tiers', need ot check that, because CheckoutItemQuantity uses 'price.tiers' to find if there's a max, to prevent user from trying to change quantity to something higher than the max if it is defined -->
                                    <CheckoutItemQuantity :price="item" :priceId="item.price_id" :quantity="item.quantity" @update-quantity="updateQuantity" v-if="!item.is_free && !item.is_flat_price && !item.is_metered && !item.is_custom_price"/>
                                </td>
                                <td style="text-align: end;">
                                    <template v-if="item.is_metered">
                                        <!-- TODO: "varies"? "starting at"? -->
                                        {{ formatAmount(item.currency, item.subtotal_csu) }}
                                    </template>
                                    <template v-if="!item.is_metered">
                                        {{ formatAmount(item.currency, item.subtotal_csu) }}
                                    </template>
                                    <template v-if="item.is_custom_price">
                                        {{ formatAmount(item.currency, item.subtotal_csu) }}
                                        <!-- TODO: just display the custom price; this isn't the place to edit it, but we can show a button to return to product page to edit the amount and then they can return to checkout from there -->
                                    </template>
                                </td>
                            </tr>
                            <!-- <tr style="background-color: #cccccc;">
                                <th style="height: 4px;"></th>
                                <th style="height: 4px;"></th>
                                <th style="height: 4px;"></th>
                            </tr> -->
                            <!-- TODO: there should be a tr v-for any coupon to be applied showing the impact, and a row with a button to enter a code -->
                            <tr v-for="(coupon, idx) in cart.coupons" :key="'coupon__'+idx">
                                <td style="text-align: start;"> <!-- colspan="2" if we include the checkboxes -->
                                    {{ coupon.code }}
                                </td>
                                <td></td>
                                <td style="text-align: end; font-weight: bold;">
                                    <span v-if="Number.isInteger(coupon.value_csu)">
                                    {{ formatAmount(coupon.currency, coupon.value_csu) }}
                                    </span>
                                </td>
                            </tr>
                            <tr v-if="!displayAddCouponForm">
                                <td style="text-align: start;"> <!-- colspan="2" if we include the checkboxes -->
                                    <!-- Coupon -->
                                </td>
                                <!-- <td></td> -->
                                <td colspan="2" style="text-align: end;">
                                    <!-- {{ formatAmount(total.currency, total.amount_csu) }} -->
                                    <TextButton :color="primaryColor" @click="addCoupon" underline>Add coupon</TextButton>
                                    <!-- <v-btn :style="primaryButtonStyle" icon x-small>
                                        <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                                    </v-btn> -->
                                </td>
                            </tr>
                            <tr v-if="displayAddCouponForm">
                                <td colspan="3" style="text-align: start;"> <!-- colspan="2" if we include the checkboxes -->
                                    <v-text-field v-model="newCouponCode" ref="newCouponCodeRef" outlined label="Coupon code" placeholder="Enter code" hide-details dense class="my-2">
                                        <template #append-outer>
                                            <v-btn :style="primaryButtonStyle" icon x-small @click="submitCouponCode" class="mx-1">
                                                <font-awesome-icon :icon="['fas', 'check']" fixed-width></font-awesome-icon>
                                            </v-btn>
                                            <TextButton :color="primaryColor" @click="submitCouponCode" underline><span class="text-body-2">Submit</span></TextButton>
                                            <span class="mx-2"></span>
                                            <v-btn style="color: #ffffff; background-color: #555555;" icon x-small @click="cancelCouponCode" class="mx-1">
                                                <font-awesome-icon :icon="['fas', 'times']" fixed-width></font-awesome-icon>
                                            </v-btn>
                                            <TextButton color="#555555" @click="cancelCouponCode" underline><span class="text-body-2">Cancel</span></TextButton>
                                        </template>
                                    </v-text-field>
                                </td>
                            </tr>
                            <tr v-for="(total, idx) in cart.totals" :key="'total__'+idx">
                                <td style="text-align: start;"> <!-- colspan="2" if we include the checkboxes -->
                                    Total {{ total.currency }}
                                </td>
                                <td></td>
                                <td style="text-align: end; font-weight: bold;">
                                    {{ formatAmount(total.currency, total.amount_csu) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <!-- TODO: subtotal, shipping/taxes, total -->
                <v-card-actions>
                    <v-spacer/>
                    <TextButton :color="primaryColor" @click="navigateToCatalog" underline>Add more items</TextButton> <!-- ALTERNATIVELY: 'Continue shopping -->
                    <v-btn :style="primaryButtonStyle" @click="finalize" class="ml-6" v-if="cart.items.length > 0">Continue</v-btn>
                </v-card-actions>
            </v-card>
            {{ JSON.stringify(couponList) }}
            </template>
            <!-- TODO:  if there's an error loading the cart, show temporary error: -->
            <v-card elevation="2" v-if="isViewReady && !cart">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>Maintenance</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>The server is temporarily unavailable. We are going to automatically retry until it's ready. Please wait...</p>
                    <!-- <p>Return to the last page and contact the emergency home for details.
                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
/* remove hint area from item selection checkbox */
.v-input.v-input--checkbox.checkout-item-selection .v-messages {
    display: none;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
// import { toMillis } from '@libertyio/time-util-js';
// import { loadStripe } from '@stripe/stripe-js';
import CheckoutItemQuantity from '@/components/CheckoutItemQuantity.vue';
import TextButton from '@/components/TextButton.vue';
// import CheckoutItemCustomAmount from '@/components/CheckoutItemCustomAmount.vue';
// import { isValidEmail } from '@/sdk/input';

export default {
    components: {
        CheckoutItemQuantity,
        TextButton,
        // CheckoutItemCustomAmount,
    },
    data: () => ({
        product: null,
        isViewReady: false,
        email: null,
        emailError: null,
        emailErrorTimeout: null,
        checkoutTimestamp: null,
        // couponList: [], // each item { couponId, code, value }
        // couponRefCounter: 0,
        // mode: 'prompt', // 'prompt' shows line items and continue button, 'email' prompts for email
        displayAddCouponForm: false,
        newCouponCode: '',
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
            cart: (state) => state.cart,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        frontLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-front', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'front' };
            }
            return link;
        },
        catalogLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-catalog-search', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'brand-catalog-search' };
            }
            return link;
        },
        shippingLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-shipping', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-shipping' };
            }
            return link;
        },
        billingLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-shipping', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-shipping' };
            }
            return link;
        },
        finalizeLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-finalize', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-finalize' };
            }
            return link;
        },
        paymentLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-payment', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-payment' };
            }
            return link;
        },
        checkoutAccountLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-account', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-account' };
            }
            return link;
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        // isAuthenticated(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.init();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.init();
            }
        },
        $route(newValue, oldValue) {
            if (newValue.query?.accountId && newValue.query.accountId !== oldValue.query?.accountId) {
                console.log(`Cart.vue: watch route detected new account id: ${this.$route.query.accountId}`);
                this.switchAccount(newValue.query.accountId);
            }
        },
    },
    methods: {
        // redirectAuthenticatedUser() {
        //     // TODO: we need to check query parameters, if there's an organization id redirect to the dashboard for that organization, otherwise if there's only one organization, go to that one, or if there's more than one show the organization selection , and if the user doens't have any organizations then show a message that they need to contact the administrator to be added to an organization
        //     this.$router.replace({ name: 'dashboard' });
        // },
        // TODO: add a function to remove item from shopping cart (remove it completely, vs, just setting quantity to zero), add item
        async addQuantity({ priceId, quantity }) {
            try {
                this.$store.commit('loading', { addQuantity: true });
                const item = {
                    price_id: priceId,
                    quantity,
                };
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'add', item });
                if (response.isEdited) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot increase quantity' });
                }
            } catch (err) {
                console.error('addQuantity failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot increase quantity', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { addQuantity: false });
            }
        },
        async subtractQuantity({ priceId, quantity }) {
            try {
                this.$store.commit('loading', { subtractQuantity: true });
                const item = {
                    price_id: priceId,
                    quantity,
                };
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'sub', item });
                if (response.isEdited) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot decrease quantity' });
                }
            } catch (err) {
                console.error('subtractQuantity failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot decrease quantity', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { subtractQuantity: false });
            }
        },
        async editQuantity({ priceId, quantity }) {
            try {
                this.$store.commit('loading', { editQuantity: true });
                const item = {
                    price_id: priceId,
                    quantity,
                };
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'edit', item });
                if (response.isEdited) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot set quantity' });
                }
            } catch (err) {
                console.error('editQuantity failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot set quantity', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { editQuantity: false });
            }
        },
        async replaceCustomAmount({ priceId, amount }) {
            try {
                this.$store.commit('loading', { replaceCustomAmount: true });
                const item = {
                    price_id: priceId,
                    amount,
                };
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'replace', item });
                if (response.isEdited) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot set amount' });
                }
            } catch (err) {
                console.error('replaceCustomAmount failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot set amount', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { replaceCustomAmount: false });
            }
        },
        // removes the item from the cart completely
        async removeFromCart(priceId) {
            try {
                this.$store.commit('loading', { subtractQuantity: true });
                const item = {
                    price_id: priceId,
                };
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'del', item });
                if (response.isEdited) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot remove item from cart' });
                }
            } catch (err) {
                console.error('removeFromCart failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot remove item from cart', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { subtractQuantity: false });
            }
        },
        async clearCart() {
            try {
                this.$store.commit('loading', { clearCart: true });
                const response = await this.$client.site(this.brandprofile).cart.delete();
                if (response.isDeleted) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot clear shopping cart' });
                }
            } catch (err) {
                console.error('clearCart failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot clear shopping cart', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { clearCart: false });
            }
        },
        updateQuantity(input) {
            console.log(`updateQuantity: ${JSON.stringify(input)}`);
            const { priceId, quantity } = input;
            // const idx = this.cart.items.findIndex((item) => item.priceId === priceId);
            // if (idx > -1) {
            //     const diff = quantity - this.cart.items[idx].quantity;
            //     if (diff > 0) {
            //         this.addQuantity({ priceId, quantity: diff });
            //     } else {
            //         this.subtractQuantity({ priceId, quantity: Math.abs(diff) });
            //     }
            // }
            this.editQuantity({ priceId, quantity });
        },
        async finalize() {
            try {
                this.error = false;
                // 'payment_intent', 'payment_intent_client_secret', and 'redirect_status=succeeded'
                this.$store.commit('loading', { finalize: true });
                // send a 'finalize' request with no parameters to get requirements for the items currently in the cart
                const response = await this.$client.site(this.brandprofile).cart.finalize({});
                if (response) {
                    this.$store.commit('cart', response);
                } else {
                    this.$store.commit('cart', null);
                }
                if (this.cart.status === 'ready') {
                    // TODO: send the user to paymentLink instead, and have an indication there that user is going to do a guest checkout, or is signed in and has account X selected, and a button to use a different account would take user to checkoutAccountLink ?
                    // this.$nav.push(this.checkoutAccountLink);
                    this.$nav.push(this.paymentLink);
                    return;
                }

                // finalize API either responds with an error (400, 401, 403) or a status of 'ready' or 'finalize';
                // if (this.cart.status === 'finalize') {
                if (this.cart.finalize.includes('require_signup')) {
                    this.$nav.push(this.checkoutAccountLink);
                    return;
                }
                if (this.cart.finalize.includes('require_shipping')) {
                    this.$nav.push(this.shippingLink);
                    return;
                }
                if (this.cart.finalize.includes('require_billing')) {
                    this.$nav.push(this.billingLink);
                    return;
                }
                // this.cart.finalize.includes('require_name') || this.cart.finalize.includes('require_email')
                this.$nav.push(this.finalizeLink);
                // return;
                // }
            } catch (err) {
                console.error('finalize failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { finalize: false });
            }
        },
        async cancel() {
            await this.clearCart();
            this.$nav.push(this.catalogLink);
        },
        formatAmount(currency, amount) {
            // return fromCurrencyAmountCSU(this.price.currency, amount).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(currency, amount ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', { // TODO: localization, if we know user's locale use that instead of en-US
                currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        navigateToCatalog() {
            this.$nav.push(this.catalogLink);
        },
        async switchAccount(accountId) {
            // TODO: load account first to make sure user has access to it?
            this.$nav.set('account_id', accountId);
            const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'set_account', account_id: accountId });
            console.log(`switchAccount response: ${JSON.stringify(response)}`);
        },
        async init() {
            await this.$store.dispatch('loadCart');
            this.isViewReady = true;
        },
        async addCoupon() {
            if (this.displayAddCouponForm) {
                if (this.newCouponCode) {
                    // auto-submit what is already in the form so user can add another one
                    await this.submitCouponCode();
                }
            }
            this.newCouponCode = '';
            this.displayAddCouponForm = true;
            this.$nextTick(() => {
                setTimeout(() => { this.$activateInput('newCouponCodeRef'); }, 1);
            });
            /*
            const lastIdx = this.couponList.length - 1;
            let couponRef;
            if (lastIdx < 0 || this.couponList[lastIdx]?.couponId) {
                // there are no coupons yet OR the last coupon was successfully added
                this.couponRefCounter += 1;
                couponRef = `couponInput_${this.couponRefCounter}`;
                this.couponList.push({ couponId: null, code: '', currency: 'USD', value_csu: null, ref: couponRef });
            } else {
                // there's an existing coupon code input that hasn't been completed, so highlight that one
                couponRef = `couponInput_${this.couponRefCounter}`;
            }
            this.$nextTick(() => {
                setTimeout(() => { this.$activateInput(couponRef); }, 1);
            });
            */
        },
        async submitCouponCode() {
            try {
                this.$store.commit('loading', { submitCouponCode: true });
                // const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
                // const code = inputRef.$el.value;
                const code = this.newCouponCode;
                if (!code) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Enter a coupon code' });
                    return;
                }
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'add_coupon', code });
                if (response.type === 'fault') {
                    switch (response.fault) {
                    /*
                    case 'early': {
                        if (response.fault_params?.not_before) {
                            const startDate = new Date(response.fault_params.not_before).toDateString();
                            const today = new Date().toDateString();
                            if (startDate === today) {
                                const startTime = new Date(response.fault_params.not_before).toTimeString();
                                this.$bus.$emit('snackbar', { type: 'error', headline: 'Too early to use this coupon', message: `Coupon valid today at ${startTime}` });
                            } else {
                                this.$bus.$emit('snackbar', { type: 'error', headline: 'Too early to use this coupon', message: `Coupon valid on ${startDate}` });
                            }
                        } else {
                            this.$bus.$emit('snackbar', { type: 'error', headline: 'Too early to use this coupon' });
                        }
                        break;
                    }
                    case 'expired': {
                        if (response.fault_params?.not_after) {
                            const endDate = new Date(response.fault_params.not_after).toDateString();
                            const today = new Date().toDateString();
                            if (endDate === today) {
                                const endTime = new Date(response.fault_params.not_after).toTimeString();
                                this.$bus.$emit('snackbar', { type: 'error', headline: 'Too late to use this coupon', message: `Coupon expired today at ${endTime}` });
                            } else {
                                this.$bus.$emit('snackbar', { type: 'error', headline: 'Too late to use this coupon', message: `Coupon expired on ${endDate}` });
                            }
                        } else {
                            this.$bus.$emit('snackbar', { type: 'error', headline: 'Coupon expired' });
                        }
                        break;
                    }
                    */
                    case 'invalid_coupon_code':
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Invalid coupon code', message: 'We did not find this code. It may be too early or too late to use this code. Please refer to the offer for details or contact customer support.' });
                        break;
                    default:
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Coupon not eligible for this purchase' });
                        break;
                    }
                    return;
                }
                // reload cart to see the changes
                await this.$store.dispatch('loadCart');
            } catch (err) {
                console.error('submitCouponCode failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot add coupon', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { submitCouponCode: false });
            }
        },
        cancelCouponCode() {
            this.newCouponCode = '';
            this.displayAddCouponForm = false;
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.$route.query.accountId) {
            this.switchAccount(this.$route.query.accountId);
        }
        if (this.brandprofile) {
            this.init();
        }
    },
};
</script>
