<template>
    <!-- NOTE: we omit label because this input appears in a table and the column heading is already a label -->
    <v-text-field v-model="editableQuantity" dense :color="primaryColor" :error-messages="quantityInvalidErrorMessage" @keyup.enter.prevent="updateQuantity" @blur="updateQuantity" :label="label">
        <!-- <template #append>
            <v-btn icon :color="primaryColor" x-small @click="calculate">
                <font-awesome-icon :icon="['fas', 'calculator']" fixed-width style="font-size: 16px;"/>
            </v-btn>
        </template> -->
    </v-text-field>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    props: ['price', 'priceId', 'quantity'],
    data: () => ({
        editableQuantity: null,
        updateQuantityTimestamp: null,
        quantityInvalidError: false,
    }),
    computed: {
        ...mapState({
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            accentColor: 'accentColor',
        }),
        quantityInvalidErrorMessage() {
            return this.quantityInvalidError ? '#' : null;
        },
        min() {
            if (this.price?.tiers?.length > 0 && Number.isInteger(this.price.tiers[0].min)) {
                return this.price.tiers[0].min;
            }
            return null;
        },
        max() {
            if (this.price?.tiers?.length > 0 && Number.isInteger(this.price.tiers[0].max)) {
                return this.price.tiers[0].max;
            }
            return null;
        },
        limitsText() {
            const minText = this.min ? `min ${this.min}` : '';
            const maxText = this.max ? `max ${this.max}` : '';
            if (minText || maxText) {
                const list = [];
                if (minText) {
                    list.push(minText);
                }
                if (maxText) {
                    list.push(maxText);
                }
                return list.join(', ');
            }
            return '';
        },
        label() {
            const limits = this.limitsText;
            return limits ? `Quantity (${limits})` : 'Quantity';
        },
    },
    watch: {
        quantity(newValue) {
            this.editableQuantity = newValue;
        },
        editableQuantity() {
            this.updateQuantity();
        },
    },
    methods: {
        updateQuantity() {
            if (Number.isInteger(this.updateQuantityTimestamp) && this.updateQuantityTimestamp + 500 > Date.now()) {
                return;
            }
            this.updateQuantityTimestamp = Date.now();
            let quantity;
            if (Number.isInteger(this.editableQuantity)) {
                quantity = this.editableQuantity;
            } else if (typeof this.editableQuantity === 'string' && this.editableQuantity === '') {
                this.quantityInvalidError = false;
                this.$emit('update-quantity', { priceId: this.priceId, quantity: 0 });
                return;
            } else if (typeof this.editableQuantity === 'string') {
                quantity = Number.parseInt(this.editableQuantity, 10);
            } else {
                this.quantityInvalidError = true;
                this.$emit('update-quantity', { priceId: this.priceId, quantity: 0 });
                return;
            }
            if (!Number.isInteger(quantity) || quantity < 0) {
                this.quantityInvalidError = true;
                this.$emit('update-quantity', { priceId: this.priceId, quantity: 0 });
                return;
            }
            if (Number.isInteger(this.min) && quantity < this.min) {
                this.quantityInvalidError = true;
                this.$emit('update-quantity', { priceId: this.priceId, quantity: this.min });
                return;
            }
            if (Number.isInteger(this.max) && quantity > this.max) {
                this.quantityInvalidError = true;
                this.$emit('update-quantity', { priceId: this.priceId, quantity: this.max });
                return;
            }
            this.editableQuantity = quantity.toString();
            this.quantityInvalidError = false;
            this.$emit('update-quantity', { priceId: this.priceId, quantity });
        },
    },
    mounted() {
        this.editableQuantity = this.quantity;
    },
};
</script>
